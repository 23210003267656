import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';

import ZoneGridPage from './pages/ZoneGrid';
import { Helmet } from 'react-helmet';
import createHistory from 'history/createBrowserHistory';

const history = createHistory();

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const App = () => (
            <div>
                    <Helmet>
                        <style>{'body { background-color: white; }'}</style>
                        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=0" />
                    </Helmet>
                    <Switch>
                        <Route exact path="/active" component={ZoneGridPage} />
                        <Route component={ZoneGridPage} />
                    </Switch>
            </div>
        )
        return (
            <Switch>
                <App />
            </Switch>
        );
    }
}
export default (App);